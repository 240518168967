@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* background-color: #16181b; */
  background-image: url("https://res.cloudinary.com/dwd3juhqv/image/upload/v1673612600/background_fgmgag.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.bg-background {
  --tw-bg-opacity: 1;
  background-color: #16181b;
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(1 255 255 / var(--tw-text-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(1 255 255 / var(--tw-bg-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(1 255 255 / var(--tw-border-opacity));
}

.react-tel-input .flag-dropdown {
  background-color: #16181b !important;
  border-radius: 0.75rem /* 12px */ !important;
  border: none !important;
  margin: 4px !important;
}

.react-tel-input .flag-dropdown .open {
  background-color: #16181b !important;
  border-radius: 0.75rem /* 12px */ !important;
}

.react-tel-input .flag-dropdown :hover {
  background-color: #16181b !important;
}

.react-tel-input .country-list {
  border-radius: 0.75rem /* 12px */ !important;
}

.react-tel-input .country {
  --tw-bg-opacity: 0.9;
  background-color: rgb(22 24 27 / var(--tw-bg-opacity)) !important;
  color: white !important;
}

.react-tel-input .search {
  --tw-bg-opacity: 0.9;
  background-color: rgb(22 24 27 / var(--tw-bg-opacity)) !important;
}

.react-tel-input .search-emoji {
  /* display: none !important; */
}

.react-tel-input .search-box {
  --tw-bg-opacity: 0.6;
  background-color: rgb(22 24 27 / var(--tw-bg-opacity)) !important;
  color: white !important;
  border-radius: 0.75rem /* 12px */ !important;
}

.apple-store-logo {
  background-image: url("https://res.cloudinary.com/dwd3juhqv/image/upload/c_fit,f_auto,w_400/v1673595643/app_store.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.google-play-logo {
  background-image: url("https://res.cloudinary.com/dwd3juhqv/image/upload/c_fit,f_auto,w_400/v1673595644/google_play.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.privacy-eula {
  color: #00cfcf;
}
